import { autoserialize, autoserializeAs } from 'cerialize';
import * as moment from 'moment';
import { BaseDonationLead, IBaseDonationLead } from './base-donation-lead';
import { CallDetails, ICallDetails } from './call-details';
import { Donation, IDonation } from './donation';
import { DonationMeta, IDonationMeta } from './donation-meta';
import { LeadChannel } from './lead-channel';
import { LeadUTM } from './lead-utm';

export class Lead extends BaseDonationLead implements ILead {
  @autoserializeAs('donation_id') donationId?: string;
  @autoserializeAs('donation') donation?: Donation;

  @autoserializeAs('call_details') callDetails?: CallDetails;

  @autoserializeAs('marketing_source') marketingSource?: string;
  @autoserialize utm?: LeadUTM;
  @autoserialize channel?: LeadChannel;

  @autoserialize meta?: DonationMeta;

  @autoserialize quote_check?: boolean | null;

  constructor(payload: ILead) {
    super(payload);
    this.donation = payload?.donation
      ? new Donation(payload.donation)
      : undefined;
    this.donationId = payload?.donationId || payload?.donation?.id;

    this.callDetails = payload?.callDetails
      ? new CallDetails(payload.callDetails)
      : undefined;

    this.marketingSource = payload?.marketingSource;
    this.utm = payload?.utm ? new LeadUTM(payload.utm) : undefined;
    this.channel = payload?.channel;

    this.meta = new DonationMeta(payload?.meta);

    this.quote_check = payload?.quote_check;
  }

  get isExpired(): boolean {
    return moment().isAfter(moment(this.expiresAt));
  }

  get total(): number {
    return this.originalQuote;
  }
  
  get totalSponsoredAmount(): number {
    return this.originalSponsoredAmount;
  }

  get donatableSmall(): number {
    if (this.discount?.valid) {
      return this.specification.small;
    } else {
      return 0;
    }
  }
}

export interface ILead extends IBaseDonationLead {
  donationId?: string;
  donation?: IDonation;

  callDetails?: ICallDetails;

  marketingSource?: string;
  utm?: LeadUTM;
  channel?: LeadChannel;

  meta?: IDonationMeta;

  quote_check?: boolean | null;
}
