export enum CourierType {
  UBER = 'uber-direct',
  PARTNER = 'resupply-partner',
}

export const COURIERS = {
  'uber-direct': {
    value: 'uber-direct',
    name: 'Uber Direct',
  },
};

