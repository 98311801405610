import { CurrencyPipe } from '@angular/common';
import { autoserialize, autoserializeAs } from 'cerialize';
import * as moment from 'moment';
import { Address, IAddress } from './address';
import { BaseDTO, IBaseDTO } from './base-dto';
import { Charity, ICharity } from './charity';
import { Contact, IContact } from './contact';
import { Courier } from './courier';
import { CourierType } from './courier-type';
import { DonationDiscount, IDonationDiscount } from './donation-discount';
import { DonationPayment, IDonationPayment } from './donation-payment';
import { Fee } from './fee';
import { OwnerType } from './owner';
import { PartOfDay } from './part-of-day';
import { IPartner, Partner } from './partner';
import { IPricing, Pricing } from './pricing';
import { Quote } from './quote';
import { ISpecification, Specification } from './specification';
import { SponsorshipAlgorithm } from './sponsorship-algorithm';
import { CharityStore, ICharityStore } from './store';
import { ITruck, Truck } from './truck';

export abstract class BaseDonationLead extends BaseDTO {
  @autoserialize id?: string;

  @autoserializeAs('created_at') createdAt?: string;
  @autoserializeAs('updated_at') updatedAt?: string;
  @autoserializeAs('expires_at') expiresAt?: string;

  @autoserialize date?: string;
  @autoserializeAs('part_of_day') partOfDay?: PartOfDay;
  @autoserializeAs(Contact, 'donor') donor: Contact;
  @autoserializeAs(Address, 'address') address: Address;
  @autoserialize instructions?: string;

  @autoserializeAs(Charity, 'charity') charity?: Charity;
  @autoserializeAs('charity_id') charityId?: string | null;
  @autoserializeAs(CharityStore, 'store') store?: CharityStore | null;
  @autoserializeAs('store_id') storeId?: string;

  @autoserializeAs(Partner, 'partner') partner?: Partner | null;
  @autoserializeAs('partner_id') partnerId?: string | null;
  @autoserializeAs(Truck, 'vehicle') vehicle?: Truck | null;
  @autoserializeAs('vehicle_id') vehicleId?: string | null;

  @autoserialize courier?: Courier;

  @autoserializeAs(Fee, 'fee') fee?: Fee | null;
  @autoserializeAs(Pricing, 'pricing') pricing: Pricing;

  @autoserialize quote?: Quote;

  @autoserializeAs(DonationPayment, 'payment') payment: DonationPayment;

  @autoserializeAs(Specification, 'specification') specification: Specification;

  @autoserialize discount?: DonationDiscount | null;
  @autoserialize sponsorship_algorithm?: SponsorshipAlgorithm;

  @autoserializeAs('item_ids') itemIds?: string[];
  @autoserializeAs('donation_items') donationItems?: string | null;

  @autoserializeAs('owner_type') ownerType?: OwnerType;
  @autoserializeAs('owner_id') ownerId?: string;

  constructor(payload: IBaseDonationLead) {
    super(payload);
    this.id = payload?.id?.toString();

    this.createdAt = payload?.createdAt;
    this.updatedAt = payload?.updatedAt;
    this.expiresAt = payload?.expiresAt;

    this.date = payload?.date;
    this.partOfDay = payload?.partOfDay;
    this.donor = new Contact(payload?.donor);
    this.address = new Address(payload?.address);
    this.instructions = payload?.instructions;

    this.charity = payload?.charity ? new Charity(payload?.charity) : undefined;
    this.charityId = payload?.charityId?.toString() || this.charity?.id || null;
    this.store = payload?.store ? new CharityStore(payload?.store) : undefined;
    this.storeId = payload?.storeId?.toString() || this.store?.id;

    this.partner = payload?.partner ? new Partner(payload?.partner) : undefined;
    this.partnerId =
      payload?.partnerId?.toString() || this?.partner?.id || null;
    this.vehicle = payload?.vehicle ? new Truck(payload.vehicle) : undefined;
    this.vehicleId = payload?.vehicleId?.toString() || this.vehicle?.id;

    this.courier = payload?.courier ? new Courier(payload.courier) : undefined;

    this.fee = payload?.fee ? new Fee(payload?.fee) : undefined;
    this.pricing = new Pricing(payload?.pricing);

    this.quote = payload?.quote ? new Quote(payload.quote) : undefined;

    this.payment = new DonationPayment(payload?.payment);

    this.specification = new Specification(payload?.specification);

    this.discount = payload?.discount
      ? new DonationDiscount(payload.discount)
      : undefined;
    this.sponsorship_algorithm = payload?.sponsorship_algorithm
      ? new SponsorshipAlgorithm(payload?.sponsorship_algorithm)
      : undefined;

    this.itemIds = payload?.itemIds?.map((id) => id.toString());
    this.donationItems = payload?.donationItems;

    this.ownerType = payload?.ownerType;
    this.ownerId = payload?.ownerId;
  }

  public get zip(): string | undefined {
    return this.address?.zip;
  }

  public get state(): string | undefined {
    return this.address?.state;
  }

  public get phone(): string {
    return this.donor?.phone;
  }

  public get fullAddress(): string {
    const addr =
      (this.address?.street ? this.address?.street + ', ' : '') +
      (this.address?.city ? this.address?.city + ', ' : '') +
      (this.address?.state ? this.address?.state + ', ' : '') +
      (this.address?.zip ? this.address?.zip : '') +
      ((this.address?.secondary?.length || 0) > 0
        ? ' #' + this.address?.secondary
        : '');
    return addr.length > 0 ? addr : '/';
  }

  public get mapBoxAddress(): string {
    return this.address?.mapBoxAddress;
  }

  get isPast(): boolean {
    return moment()
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .isAfter(moment(this.date));
  }

  public getFee(): Fee | undefined | null {
    return this.fee || this.partner?.fee;
  }

  get originalQuote() {
    if (this.quote) return this.quote.total_amount;
    return Pricing.getTotalPriceFormatted(this.specification, this.pricing);
  }

  get originalSponsoredAmount(): number {
    return Pricing.calculateSponsoredAmount(
      this.specification,
      this.pricing,
      this.discount,
      this.sponsorship_algorithm,
      this.specification.small
    );
  }

  abstract get total(): number;
  abstract get totalSponsoredAmount(): number;

  totalAmount(currency: CurrencyPipe): string {
    return currency.transform(this.total) || '';
  }

  abstract get donatableSmall(): number;

  //#region PickupType
  get isUberPickup(): boolean {
    return this.quote?.courier === CourierType.UBER;
  }

  get isPartnerPickup(): boolean {
    return !this.quote || this.quote?.courier === CourierType.PARTNER;
  }
  //#endregion PickupType
}

export interface IBaseDonationLead extends IBaseDTO {
  id?: string;

  createdAt?: string;
  updatedAt?: string;
  expiresAt?: string | null;

  date?: string;
  partOfDay?: PartOfDay;
  donor: IContact;
  address: IAddress;
  instructions?: string;

  charity?: ICharity;
  charityId?: string | null;
  store?: ICharityStore | null;
  storeId?: string | null;

  partner?: IPartner | null;
  partnerId?: string | null;
  vehicle?: ITruck | null;
  vehicleId?: string | null;

  courier?: Courier;

  fee?: Fee | null;
  pricing: IPricing;

  quote?: Quote;

  payment?: IDonationPayment;

  specification: ISpecification;

  discount?: IDonationDiscount | null;
  sponsorship_algorithm?: SponsorshipAlgorithm;

  itemIds?: string[];
  donationItems?: string | null;

  ownerType?: OwnerType;
  ownerId?: string;
}
