<ng-template #Data let-change="change">
  <rspl-card [cardTitle]="change.user">
    <div fxLayout="column" class="history-data-wrapper">
      <table class="static-table full-width">
        <thead>
          <tr>
            <th>{{ change.oneValue ? '' : 'Changed' }}</th>
            <th *ngIf="!change.oneValue">From</th>
            <th *ngIf="!change.oneValue">To</th>
            <th
              class="initial"
              *ngIf="initialEvent && change.event !== initialEvent"
            ></th>
            <th *ngIf="change.oneValue">Value</th>
            <th *ngIf="initialEvent && change.event !== initialEvent">
              Initial Value
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let field of change.fields">
            <td>{{ field.name }}</td>
            <td *ngIf="!change.oneValue">
              <p [innerHTML]="field.oldValue"></p>
            </td>
            <td>
              <p [innerHTML]="field.newValue"></p>
            </td>
            <td
              class="initial"
              *ngIf="initialEvent && change.event !== initialEvent"
            ></td>
            <td *ngIf="initialEvent && change.event !== initialEvent">
              <p [innerHTML]="initialValue[field.name]"></p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </rspl-card>
</ng-template>

<div class="history-wrapper">
  <button
    mat-button
    (click)="changeSort()"
    [ngStyle]="{
      width: smallWindows.includes(windowSize) ? '100%' : 'auto',
      'margin-left.px': smallWindows.includes(windowSize) ? 0 : 159
    }"
  >
    <span fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
      <mat-icon style="min-width: 15px; position: relative">{{
        sort === 'asc' ? 'arrow_downward' : 'arrow_upward'
      }}</mat-icon>
    </span>
  </button>
  <mat-accordion *ngIf="smallWindows.includes(windowSize)" [multi]="true">
    <mat-expansion-panel *ngFor="let change of sortedChanges">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ change.event }}
        </mat-panel-title>
        <mat-panel-description>
          {{ change.time }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container
        [ngTemplateOutlet]="Data"
        [ngTemplateOutletContext]="{ change: change }"
      ></ng-container>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="history" *ngIf="!smallWindows.includes(windowSize)">
    <div class="separator"></div>
    <div *ngFor="let change of sortedChanges" class="timeline-entry">
      <p class="date">{{ change.time }}</p>
      <p class="event">{{ change.event }}</p>
      <ng-container
        [ngTemplateOutlet]="Data"
        [ngTemplateOutletContext]="{ change: change }"
      ></ng-container>
    </div>
  </div>
</div>
