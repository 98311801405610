import { HistoryEntity } from './history-entity';
import { HistoryService } from './history.service';

export interface HistoryConfig extends HistoryConfigBase {
  itemIds: string[];
}

interface HistoryConfigBase {
  entity: HistoryEntity;
  ignoreFields: string[];
  replaceFields: { [key: string]: string };
  users: { [key: string]: string };
  initialEvent?: string;
  fieldTypes: {
    [key: string]:
      | 'string'
      | 'object'
      | 'date'
      | 'dateTime'
      | 'currency'
      | 'objectList'
      | 'objectObject'
      | 'listObject'
      | 'percent';
  };
  specialDiff?: (
    change: any[],
    position: number,
    parent: string,
    key: string
  ) => string | undefined;
  shouldGetItem: boolean;
}

export const HISTORY_CONFIGURATION: { [key: string]: HistoryConfigBase } = {};
HISTORY_CONFIGURATION[HistoryEntity.DONATION] = {
  entity: HistoryEntity.DONATION,
  shouldGetItem: true,
  ignoreFields: ['updated_at', 'lock_version', 'references', 'version'],
  initialEvent: 'donation_submit',
  users: {
    donation_create: 'Donor',
    donation_submit: 'Donor',
    donation_review: 'Donor',
    donation_checkout_setup: 'Donor',
    donation_checkout_payment: 'Donor',
    donation_code_payment: 'Donor',
    donation_code_update: 'Donor',
    donation_donor_confirm: 'Donor',
    donation_donor_reschedule: 'Donor',
    donation_donor_cancel: 'Donor',
    donation_donor_accept: 'Donor',
    donation_donor_decline: 'Donor',
  },
  replaceFields: {
    gratuity: 'tip',
    etaRequestedAt: 'ETA Requested At',
    etaProvidedAt: 'ETA Provided At',
    geofenceTimestamp: 'Geofence Timestamp',
    geofenceButtonClicked: 'Geofence Button Clicked',
    geofencePopUpType: 'Geofence Pop Up Type',
    reminderState: 'Reminder State',
    lastReminderSentAt: 'Last Reminder Sent At',
    declinedExtraMile: 'Declined Extra Mile',
    manualAddress: 'Manual Address',
    requestCancel: 'Request Cancel',
    smsApproval: 'Sms Approval',
    marketingRule: 'Marketing Rule',
    marketingVariation: 'Marketing Variation',
    flowRule: 'Flow Rule',
    flowVariation: 'Flow Variation',
    donatableSmall: 'Donatable Small',
    nonDonatableSmall: 'Non Donatable Small',
  },
  fieldTypes: {
    date: 'date',
    updated_at: 'dateTime',
    created_at: 'dateTime',
    expires_at: 'dateTime',
    discarded_at: 'dateTime',
    specification: 'object',
    donor: 'object',
    payment: 'object',
    address: 'object',
    pricing: 'object',
    charity: 'object',
    partner: 'object',
    meta: 'object',
    references: 'object',
    adjusted_specification: 'object',
    discounted_specification: 'object',
    discount: 'object',
    sponsorship_algorithm: 'object',
    dock_contact: 'object',
    review: 'object',
    accepted_content: 'objectList',
    metadata: 'objectObject',
    content: 'listObject',
    gratuity: 'currency',
    fee: 'object',
    courier: 'object',
    quote: 'object',
    payment_details: 'object',
    discount_payment_details: 'object',
    'quote.total_amount': 'currency',
    'quote.application_fee': 'currency',
    'payment.stripe_application_fee': 'currency',
    'payment.stripe_fee': 'currency',
    'payment_details.total_amount': 'currency',
    'payment_details.application_fee': 'currency',
    'payment_details.stripe_fee': 'currency',
    'payment_details.net_amount': 'currency',
    'discount_payment_details.total_amount': 'currency',
    'discount_payment_details.application_fee': 'currency',
    'discount_payment_details.stripe_fee': 'currency',
    'discount_payment_details.net_amount': 'currency',
    'pricing.small': 'currency',
    'pricing.medium': 'currency',
    'pricing.large': 'currency',
    'pricing.xlarge': 'currency',
    'pricing.staircases': 'currency',
    'pricing.elevator': 'currency',
    'pricing.disassembly': 'currency',
    'pricing.base': 'currency',
    'pricing.recycling': 'currency',
    'pricing.cancellation_fee': 'currency',
    'pricing.courier_fee': 'currency',
    'pricing.booking_fee': 'currency',
    'pricing.fuel_fee': 'percent',
    'sponsorship_algorithm.max_small': 'currency',
    'sponsorship_algorithm.max_discount': 'currency',
    'sponsorship_algorithm.min_quote': 'currency',
    'discount.small': 'currency',
    'discount.max': 'currency',
    'fee.flat_amount': 'currency',
    'fee.fixed_percent': 'percent',
    'fee.level1': 'percent',
    'fee.level2': 'percent',
    'fee.level3': 'percent',
    'metadata.etaRequestedAt': 'dateTime',
    'metadata.etaProvidedAt': 'dateTime',
    'metadata.geofenceTimestamp': 'dateTime',
  },
  specialDiff: (
    change: any[],
    position: number,
    parent: string,
    key: string
  ) => {
    if (
      parent === 'pricing' ||
      (parent === 'payment' && key === 'stripe_application_fee')
    ) {
      return `${HistoryService.getFieldName(key, {
        gratuity: 'tip',
      })}: ${
        change[position] ? '$' + (change[position][key] / 100).toFixed(2) : ''
      }`;
    }
    return undefined;
  },
};
HISTORY_CONFIGURATION[HistoryEntity.LEAD] = {
  entity: HistoryEntity.LEAD,
  shouldGetItem: true,
  ignoreFields: ['updated_at', 'lock_version', 'references', 'version'],
  users: {
    donation_create: 'Donor',
    donation_submit: 'Donor',
    donation_review: 'Donor',
    donation_checkout_setup: 'Donor',
    donation_checkout_payment: 'Donor',
    donation_code_payment: 'Donor',
    donation_code_update: 'Donor',
    donation_donor_confirm: 'Donor',
    donation_donor_reschedule: 'Donor',
    donation_donor_cancel: 'Donor',
    donation_donor_accept: 'Donor',
    donation_donor_decline: 'Donor',
  },
  replaceFields: {
    gratuity: 'tip',
    etaRequestedAt: 'ETA Requested At',
    etaProvidedAt: 'ETA Provided At',
    geofenceTimestamp: 'Geofence Timestamp',
    geofenceButtonClicked: 'Geofence Button Clicked',
    geofencePopUpType: 'Geofence Pop Up Type',
    reminderState: 'Reminder State',
    lastReminderSentAt: 'Last Reminder Sent At',
    declinedExtraMile: 'Declined Extra Mile',
    manualAddress: 'Manual Address',
    requestCancel: 'Request Cancel',
    smsApproval: 'Sms Approval',
    marketingRule: 'Marketing Rule',
    marketingVariation: 'Marketing Variation',
    flowRule: 'Flow Rule',
    flowVariation: 'Flow Variation',
    donatableSmall: 'Donatable Small',
    nonDonatableSmall: 'Non Donatable Small',
  },
  fieldTypes: {
    date: 'date',
    updated_at: 'dateTime',
    created_at: 'dateTime',
    expires_at: 'dateTime',
    discarded_at: 'dateTime',
    specification: 'object',
    donor: 'object',
    payment: 'object',
    address: 'object',
    pricing: 'object',
    charity: 'object',
    partner: 'object',
    meta: 'object',
    references: 'object',
    adjusted_specification: 'object',
    discounted_specification: 'object',
    discount: 'object',
    sponsorship_algorithm: 'object',
    dock_contact: 'object',
    review: 'object',
    accepted_content: 'objectList',
    metadata: 'objectObject',
    content: 'listObject',
    gratuity: 'currency',
    fee: 'object',
    call_details: 'object',
    courier: 'object',
    quote: 'object',
    payment_details: 'object',
    discount_payment_details: 'object',
    'quote.total_amount': 'currency',
    'quote.application_fee': 'currency',
    'payment.stripe_application_fee': 'currency',
    'payment.stripe_fee': 'currency',
    'payment_details.total_amount': 'currency',
    'payment_details.application_fee': 'currency',
    'payment_details.stripe_fee': 'currency',
    'payment_details.net_amount': 'currency',
    'discount_payment_details.total_amount': 'currency',
    'discount_payment_details.application_fee': 'currency',
    'discount_payment_details.stripe_fee': 'currency',
    'discount_payment_details.net_amount': 'currency',
    'pricing.small': 'currency',
    'pricing.medium': 'currency',
    'pricing.large': 'currency',
    'pricing.xlarge': 'currency',
    'pricing.staircases': 'currency',
    'pricing.elevator': 'currency',
    'pricing.disassembly': 'currency',
    'pricing.base': 'currency',
    'pricing.recycling': 'currency',
    'pricing.cancellation_fee': 'currency',
    'pricing.courier_fee': 'currency',
    'pricing.booking_fee': 'currency',
    'pricing.fuel_fee': 'percent',
    'sponsorship_algorithm.max_small': 'currency',
    'sponsorship_algorithm.max_discount': 'currency',
    'sponsorship_algorithm.min_quote': 'currency',
    'discount.small': 'currency',
    'discount.max': 'currency',
    'fee.flat_amount': 'currency',
    'fee.fixed_percent': 'percent',
    'fee.level1': 'percent',
    'fee.level2': 'percent',
    'fee.level3': 'percent',
  },
  specialDiff: (
    change: any[],
    position: number,
    parent: string,
    key: string
  ) => {
    if (
      parent === 'pricing' ||
      (parent === 'payment' && key === 'stripe_application_fee')
    ) {
      return `${HistoryService.getFieldName(key, {
        gratuity: 'tip',
      })}: ${
        change[position] ? '$' + (change[position][key] / 100).toFixed(2) : ''
      }`;
    }
    return undefined;
  },
};
HISTORY_CONFIGURATION[HistoryEntity.CHARITY] = {
  entity: HistoryEntity.CHARITY,
  shouldGetItem: true,
  ignoreFields: ['updated_at', 'lock_version', 'references', 'version'],
  users: {},
  replaceFields: {},
  fieldTypes: {
    date: 'date',
    updated_at: 'dateTime',
    created_at: 'dateTime',
    discarded_at: 'dateTime',
    primary_contact: 'object',
    address: 'object',
    pricing: 'object',
    meta: 'object',
    references: 'object',
    metadata: 'objectObject',
    courier_donor_percentage: 'percent',
  },
};
HISTORY_CONFIGURATION[HistoryEntity.PARTNER] = {
  entity: HistoryEntity.PARTNER,
  shouldGetItem: true,
  ignoreFields: ['updated_at', 'lock_version', 'references', 'version'],
  users: {},
  replaceFields: {},
  fieldTypes: {
    updated_at: 'dateTime',
    created_at: 'dateTime',
    discarded_at: 'dateTime',
    primary_contact: 'object',
    billing_contact: 'object',
    address: 'object',
    payment: 'object',
    meta: 'object',
    references: 'object',
    metadata: 'objectObject',
    fee: 'object',
    'fee.flat_amount': 'currency',
    'fee.fixed_percent': 'percent',
    'fee.level1': 'percent',
    'fee.level2': 'percent',
    'fee.level3': 'percent',
    acceptance_threshold: 'currency',
  },
};
HISTORY_CONFIGURATION[HistoryEntity.STORE] = {
  entity: HistoryEntity.STORE,
  shouldGetItem: true,
  ignoreFields: ['updated_at', 'lock_version', 'references', 'version'],
  users: {},
  replaceFields: {},
  fieldTypes: {
    updated_at: 'dateTime',
    created_at: 'dateTime',
    discarded_at: 'dateTime',
    primary_contact: 'object',
    address: 'object',
    meta: 'object',
    references: 'object',
    metadata: 'objectObject',
  },
};
HISTORY_CONFIGURATION[HistoryEntity.TRUCK] = {
  entity: HistoryEntity.TRUCK,
  shouldGetItem: true,
  ignoreFields: ['updated_at', 'lock_version', 'references', 'version'],
  users: {},
  replaceFields: {},
  fieldTypes: {
    updated_at: 'dateTime',
    created_at: 'dateTime',
    discarded_at: 'dateTime',
    primary_contact: 'object',
    meta: 'object',
    references: 'object',
    metadata: 'objectObject',
  },
};
HISTORY_CONFIGURATION[HistoryEntity.SCHEDULE] = {
  entity: HistoryEntity.SCHEDULE,
  shouldGetItem: false,
  ignoreFields: ['updated_at', 'lock_version', 'references', 'version'],
  users: {},
  replaceFields: {},
  fieldTypes: {
    updated_at: 'dateTime',
    created_at: 'dateTime',
    discarded_at: 'dateTime',
    meta: 'object',
    references: 'object',
    metadata: 'objectObject',
    monday_capacity: 'object',
    monday_time: 'object',
    monday_zone: 'object',
    tuesday_capacity: 'object',
    tuesday_time: 'object',
    tuesday_zone: 'object',
    wednesday_capacity: 'object',
    wednesday_time: 'object',
    wednesday_zone: 'object',
    thursday_capacity: 'object',
    thursday_time: 'object',
    thursday_zone: 'object',
    friday_capacity: 'object',
    friday_time: 'object',
    friday_zone: 'object',
    saturday_capacity: 'object',
    saturday_time: 'object',
    saturday_zone: 'object',
    sunday_capacity: 'object',
    sunday_time: 'object',
    sunday_zone: 'object',
  },
};
HISTORY_CONFIGURATION[HistoryEntity.XMILE] = {
  entity: HistoryEntity.XMILE,
  shouldGetItem: true,
  ignoreFields: ['updated_at', 'lock_version', 'references', 'version'],
  users: {},
  replaceFields: {},
  fieldTypes: {
    updated_at: 'dateTime',
    created_at: 'dateTime',
    discarded_at: 'dateTime',
    primary_contact: 'object',
    address: 'object',
    meta: 'object',
    references: 'object',
    metadata: 'objectObject',
    monday_time: 'object',
    tuesday_time: 'object',
    wednesday_time: 'object',
    thursday_time: 'object',
    friday_time: 'object',
    saturday_time: 'object',
    sunday_time: 'object',
  },
};
HISTORY_CONFIGURATION[HistoryEntity.MARKET] = {
  entity: HistoryEntity.MARKET,
  shouldGetItem: true,
  ignoreFields: ['updated_at', 'lock_version', 'references', 'version'],
  users: {},
  replaceFields: {},
  fieldTypes: {
    date: 'date',
    updated_at: 'dateTime',
    created_at: 'dateTime',
    discarded_at: 'dateTime',
    primary_contact: 'object',
    address: 'object',
    pricing: 'object',
    pricing2: 'object',
    meta: 'object',
    references: 'object',
    metadata: 'objectObject',
    'pricing.small': 'currency',
    'pricing.medium': 'currency',
    'pricing.large': 'currency',
    'pricing.xlarge': 'currency',
    'pricing.staircases': 'currency',
    'pricing.elevator': 'currency',
    'pricing.disassembly': 'currency',
    'pricing.base': 'currency',
    'pricing.recycling': 'currency',
    'pricing.cancellation_fee': 'currency',
    'pricing.courier_fee': 'currency',
    'pricing.booking_fee': 'currency',
    'pricing.fuel_fee': 'percent',
    'pricing2.small': 'currency',
    'pricing2.medium': 'currency',
    'pricing2.large': 'currency',
    'pricing2.xlarge': 'currency',
    'pricing2.staircases': 'currency',
    'pricing2.elevator': 'currency',
    'pricing2.disassembly': 'currency',
    'pricing2.base': 'currency',
    'pricing2.recycling': 'currency',
    'pricing2.cancellation_fee': 'currency',
    'pricing2.courier_fee': 'currency',
    'pricing2.booking_fee': 'currency',
    'pricing2.fuel_fee': 'percent',
    pricing2_percent: 'percent',
  },
};
HISTORY_CONFIGURATION[HistoryEntity.USER] = {
  entity: HistoryEntity.USER,
  shouldGetItem: true,
  ignoreFields: ['updated_at', 'lock_version', 'references', 'version'],
  users: {},
  replaceFields: {},
  fieldTypes: {
    date: 'date',
    updated_at: 'dateTime',
    created_at: 'dateTime',
    discarded_at: 'dateTime',
    primary_contact: 'object',
    address: 'object',
    pricing: 'object',
    meta: 'object',
    references: 'object',
    metadata: 'objectObject',
  },
};
HISTORY_CONFIGURATION[HistoryEntity.JOURNEY] = {
  entity: HistoryEntity.JOURNEY,
  shouldGetItem: false,
  ignoreFields: ['updated_at', 'lock_version', 'references', 'version'],
  users: {},
  replaceFields: {},
  fieldTypes: {
    stops: 'objectList',
    updated_at: 'dateTime',
    created_at: 'dateTime',
    discarded_at: 'dateTime',
  },
};
HISTORY_CONFIGURATION[HistoryEntity.ORGANIZATION] = {
  entity: HistoryEntity.ORGANIZATION,
  shouldGetItem: true,
  ignoreFields: ['updated_at', 'lock_version', 'references', 'version'],
  users: {},
  replaceFields: {},
  fieldTypes: {
    updated_at: 'dateTime',
    created_at: 'dateTime',
    discarded_at: 'dateTime',
    primary_contact: 'object',
    address: 'object',
    meta: 'object',
    references: 'object',
    metadata: 'objectObject',
  },
};

HISTORY_CONFIGURATION[HistoryEntity.PHONE_CONFIGURATION] = {
  entity: HistoryEntity.PHONE_CONFIGURATION,
  shouldGetItem: false,
  ignoreFields: ['updated_at', 'lock_version', 'references', 'version'],
  users: {},
  replaceFields: {},
  fieldTypes: {
    forwarding: 'objectList',
    updated_at: 'dateTime',
    created_at: 'dateTime',
    discarded_at: 'dateTime',
  },
};
