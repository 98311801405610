import { CurrencyPipe, DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  Charity,
  CharityStore,
  HistoryChange,
  HistoryEvent,
  Partner,
  Truck,
  User,
} from '@domains';
import {
  CharityService,
  MarketsService,
  PartnerService,
  StoresService,
  TrucksService,
} from '@rspl-api';
import { forkJoin, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  constructor(
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private charityService: CharityService,
    private partnerService: PartnerService,
    private truckService: TrucksService,
    private charityStoreService: StoresService,
    private marketService: MarketsService,
  ) {}

  mapChanges(
    history: HistoryEvent[],
    configUsers: { [key: string]: string },
    replaceFields: { [key: string]: string },
    fieldTypes: {
      [key: string]:
        | 'string'
        | 'object'
        | 'date'
        | 'dateTime'
        | 'currency'
        | 'objectList'
        | 'objectObject'
        | 'listObject'
        | 'percent';
    },
    ignoreFields: string[],
    showUserRole = true
  ): Observable<HistoryChange[]> {
    const changes: HistoryChange[] = [];
    const ids = {
      charity: new Set<string>(),
      partner: new Set<string>(),
      store: new Set<string>(),
      vehicle: new Set<string>(),
      market: new Set<string>(),
    };
    let hasIds = false;
    history.forEach((event) => {
      changes.push({
        event: this.getEvent(event.eventType),
        time: this.datePipe.transform(event.createdAt, 'MMM d, y hh:mm a'),
        user: this.getUser(
          event.user,
          event.eventType,
          configUsers,
          showUserRole
        ),
        oneValue: !!event.changes?.id,
        fields: this.fields(event.changes, ignoreFields).map((field) => {
          const type = this.getType(field, fieldTypes);
          let oldValue;
          let newValue;
          switch (type) {
            case 'date':
              oldValue = this.datePipe.transform(
                event.changes[field][0],
                'MMM d, y'
              );
              newValue = this.datePipe.transform(
                event.changes[field][1],
                'MMM d, y'
              );
              break;
            case 'dateTime':
              oldValue = this.datePipe.transform(
                event.changes[field][0],
                'MMM d, y hh:mm a'
              );
              newValue = this.datePipe.transform(
                event.changes[field][1],
                'MMM d, y hh:mm a'
              );
              break;
            case 'string':
              oldValue = this.convertLink(event.changes[field][0]);
              newValue = this.convertLink(event.changes[field][1]);
              break;
            case 'currency':
              oldValue =
                event.changes[field][0] !== null &&
                event.changes[field][0] !== undefined
                  ? this.currencyPipe.transform(event.changes[field][0] / 100)
                  : '';
              newValue =
                event.changes[field][1] !== null &&
                event.changes[field][1] !== undefined
                  ? this.currencyPipe.transform(event.changes[field][1] / 100)
                  : '';
              break;
            case 'percent':
              oldValue =
                event.changes[field][0] !== null &&
                event.changes[field][0] !== undefined
                  ? event.changes[field][0] * 100 + '%'
                  : '';
              newValue =
                event.changes[field][1] !== null &&
                event.changes[field][1] !== undefined
                  ? event.changes[field][1] * 100 + '%'
                  : '';
              break;
            case 'object':
              oldValue = this.getDiff(
                event.changes[field],
                0,
                field,
                replaceFields,
                fieldTypes
              );
              newValue = this.getDiff(
                event.changes[field],
                1,
                field,
                replaceFields,
                fieldTypes
              );
              break;
            case 'objectList':
              oldValue = this.getListDiff(
                event.changes[field],
                0,
                field,
                replaceFields,
                fieldTypes
              );
              newValue = this.getListDiff(
                event.changes[field],
                1,
                field,
                replaceFields,
                fieldTypes
              );
              break;
            case 'objectObject':
              oldValue = this.getObjDiff(
                event.changes[field],
                0,
                field,
                replaceFields,
                fieldTypes
              );
              newValue = this.getObjDiff(
                event.changes[field],
                1,
                field,
                replaceFields,
                fieldTypes
              );
              break;
            case 'listObject':
              const c = [
                event.changes[field][0] ? event.changes[field][0] : null,
                event.changes[field][1] ? event.changes[field][1] : null,
              ];
              oldValue = this.getListDiff(
                c,
                0,
                field,
                replaceFields,
                fieldTypes
              );
              newValue = this.getListDiff(
                c,
                1,
                field,
                replaceFields,
                fieldTypes
              );
              break;
          }
          if (field === 'charity_id') {
            if (oldValue) ids.charity.add(oldValue);
            if (newValue) ids.charity.add(newValue);
            if (oldValue || newValue) hasIds = true;
          }
          if (field === 'partner_id') {
            if (oldValue) ids.partner.add(oldValue);
            if (newValue) ids.partner.add(newValue);
            if (oldValue || newValue) hasIds = true;
          }
          if (field === 'vehicle_id') {
            if (oldValue) ids.vehicle.add(oldValue);
            if (newValue) ids.vehicle.add(newValue);
            if (oldValue || newValue) hasIds = true;
          }
          if (field === 'store_id') {
            if (oldValue) ids.store.add(oldValue);
            if (newValue) ids.store.add(newValue);
            if (oldValue || newValue) hasIds = true;
            if (oldValue || newValue) hasIds = true;
          }
          if (field === 'market_id') {
            if (oldValue) ids.market.add(oldValue);
            if (newValue) ids.market.add(newValue);
            if (oldValue || newValue) hasIds = true;
            if (oldValue || newValue) hasIds = true;
          }

          return {
            name: HistoryService.getFieldName(field, replaceFields),
            oldValue,
            newValue,
          };
        }),
        lng: event.lng,
        lat: event.lat,
      });
    });
    if (hasIds) {
      return forkJoin([
        ids.charity.size > 0
          ? this.charityService.filter({ 'id[]': [...ids.charity] })
          : of({ results: new Array<Charity>() }),
        ids.partner.size > 0
          ? this.partnerService.filter({ 'id[]': [...ids.partner] })
          : of({ results: new Array<Partner>() }),
        ids.vehicle.size > 0
          ? this.truckService.filter({ 'id[]': [...ids.vehicle] })
          : of({ results: new Array<Truck>() }),
        ids.store.size > 0
          ? this.charityStoreService.filter({ 'id[]': [...ids.store] })
          : of({ results: new Array<CharityStore>() }),
        ids.market.size > 0
          ? this.marketService.filter({ 'id[]': [...ids.market] })
          : of({ results: new Array<CharityStore>() }),
      ]).pipe(
        map(([charities, partners, trucks, charityStores, markets]) => {
          return changes.map((c) => {
            c.fields = c.fields.map((f) => {
              if (f.name === 'Charity Id') {
                f.name = f.name.replace(' Id', '');
                if (f.newValue) {
                  const c = charities.results?.find(
                    (c) => c.id === f.newValue.toString()
                  );
                  f.newValue = c
                    ? `<a class='underlined' href='/charities/${c.id}' target='_blank'>${c.name}</a>`
                    : f.newValue;
                }
                if (f.oldValue) {
                  const c = charities.results?.find(
                    (c) => c.id === f.oldValue.toString()
                  );
                  f.oldValue = c
                    ? `<a class='underlined' href='/charities/${c.id}' target='_blank'>${c.name}</a>`
                    : f.oldValue;
                }
              }
              if (f.name === 'Partner Id') {
                f.name = f.name.replace(' Id', '');
                if (f.newValue) {
                  const p = partners.results?.find(
                    (c) => c.id === f.newValue.toString()
                  );
                  f.newValue = p
                    ? `<a class='underlined' href='/partners/${p.id}' target='_blank'>${p.name}</a>`
                    : f.newValue;
                }
                if (f.oldValue) {
                  const p = partners.results?.find(
                    (c) => c.id === f.oldValue.toString()
                  );
                  f.oldValue = p
                    ? `<a class='underlined' href='/partners/${p.id}' target='_blank'>${p.name}</a>`
                    : f.oldValue;
                }
              }
              if (f.name === 'Vehicle Id') {
                f.name = f.name.replace(' Id', '');
                if (f.newValue) {
                  const t = trucks.results?.find(
                    (c) => c.id === f.newValue.toString()
                  );
                  f.newValue = t
                    ? `<a class='underlined' href='/partners/${t.partnerId}/trucks/${t.id}' target='_blank'>${t.name}</a>`
                    : f.newValue;
                }
                if (f.oldValue) {
                  const t = trucks.results?.find(
                    (c) => c.id === f.oldValue.toString()
                  );
                  f.oldValue = t
                    ? `<a class='underlined' href='/partners/${t.partnerId}/trucks/${t.id}' target='_blank'>${t.name}</a>`
                    : f.oldValue;
                }
              }
              if (f.name === 'Store Id') {
                f.name = f.name.replace(' Id', '');
                if (f.newValue) {
                  const cs = charityStores.results?.find(
                    (c) => c.id === f.newValue.toString()
                  );
                  f.newValue = cs
                    ? `<a class='underlined' href='/charities/${cs.charityId}/stores/${cs.id}' target='_blank'>${cs.name}</a>`
                    : f.newValue;
                }
                if (f.oldValue) {
                  const cs = charityStores.results?.find(
                    (c) => c.id === f.oldValue.toString()
                  );
                  f.oldValue = cs
                    ? `<a class='underlined' href='/charities/${cs.charityId}/stores/${cs.id}' target='_blank'>${cs.name}</a>`
                    : f.oldValue;
                }
              }
              if (f.name === 'Market Id') {
                f.name = f.name.replace(' Id', '');
                if (f.newValue) {
                  const m = markets.results?.find(
                    (c) => c.id === f.newValue.toString()
                  );
                  f.newValue = m
                    ? `<a class='underlined' href='/markets/${m.id}' target='_blank'>${m.name}</a>`
                    : f.newValue;
                }
                if (f.oldValue) {
                  const m = markets.results?.find(
                    (c) => c.id === f.oldValue.toString()
                  );
                  f.oldValue = m
                    ? `<a class='underlined' href='/markets/${m.id}' target='_blank'>${m.name}</a>`
                    : f.oldValue;
                }
              }
              return f;
            });
            return c;
          });
        })
      );
    } else {
      return of(changes);
    }
  }

  getEvent(eventType: string): string {
    return eventType
      .replace('_code_update', '_update')
      .split('_')
      .join(' ')
      .toUpperCase();
  }

  getUser(
    user: User | undefined,
    eventType: string,
    configUsers: { [key: string]: string },
    showUserRole = true
  ): string {
    if (user) {
      return user?.name + (showUserRole ? ` (${user?.roles.join(', ')})` : '');
    } else if (configUsers[eventType]) {
      return configUsers[eventType];
    } else {
      return 'System';
    }
  }

  fields(changes: any, ignoreFields: string[]): string[] {
    return Object.keys(changes).filter((x) => !ignoreFields.includes(x));
  }

  getType(
    field: string,
    fieldTypes: {
      [key: string]:
        | 'string'
        | 'object'
        | 'date'
        | 'dateTime'
        | 'currency'
        | 'objectList'
        | 'objectObject'
        | 'listObject'
        | 'percent';
    }
  ):
    | 'string'
    | 'object'
    | 'date'
    | 'dateTime'
    | 'currency'
    | 'objectList'
    | 'objectObject'
    | 'listObject'
    | 'percent' {
    return fieldTypes[field] || 'string';
  }

  convertLink(val: any): string {
    if (Array.isArray(val)) {
      return (
        '<ul><li>' +
        val.map((x) => this.convertLink(x)).join('</li><li>') +
        '</li></ul>'
      );
    }
    if (val?.toString()?.includes('https://')) {
      return `<a class="underlined" href=\"${val}\" target=\"_blank\">${val}</a>`;
    } else {
      return val;
    }
  }

  getDiff(
    change: any[],
    position: number,
    parent: string,
    replaceFields: { [key: string]: string },
    fieldTypes: {
      [key: string]:
        | 'string'
        | 'object'
        | 'date'
        | 'dateTime'
        | 'currency'
        | 'objectList'
        | 'objectObject'
        | 'listObject'
        | 'percent';
    },
    specialDiff?: (
      change: any[],
      position: number,
      parent: string,
      key: string
    ) => string | undefined
  ): string {
    const keys = Object.keys(change[0] || [])
      .concat(Object.keys(change[1] || []))
      .filter((v, i, a) => a.indexOf(v) === i);
    return keys
      .filter(
        (k) =>
          !change[0] ||
          !change[1] ||
          change[0][k]?.toString() !== change[1][k]?.toString()
      )
      .map((k) => {
        const special = specialDiff
          ? specialDiff(change, position, parent, k)
          : undefined;
        if (special) {
          return special;
        }
        let val = change[position] ? change[position][k] : '';
        if (val === null) {
          val = '';
        }
        val = this.convertLink(val);
        return `${HistoryService.getFieldName(
          k,
          replaceFields
        )}: ${this.formatValue(
          this.getType(`${parent ? parent + '.' : ''}${k}`, fieldTypes),
          val
        )}`;
      })
      .join('<br/>');
  }

  formatValue(type, value): string {
    switch (type) {
      case 'date':
        return this.datePipe.transform(value, 'MMM d, y');
      case 'dateTime':
        return this.datePipe.transform(value, 'MMM d, y hh:mm a');
      case 'currency':
        return value !== null && value !== undefined
          ? this.currencyPipe.transform(value / 100)
          : '';
      case 'percent':
        return value !== null && value !== undefined ? value * 100 + '%' : '';
      default:
        if (value === null || value === undefined) return '';
        return value;
    }
  }

  getListDiff(
    changes: any[],
    position: number,
    parent: string,
    replaceFields: { [key: string]: string },
    fieldTypes: {
      [key: string]:
        | 'string'
        | 'object'
        | 'date'
        | 'dateTime'
        | 'currency'
        | 'objectList'
        | 'objectObject'
        | 'listObject'
        | 'percent';
    }
  ): string {
    const ch =
      changes[0] && changes[1]
        ? changes[0].length > changes[1].length
          ? changes[0]
          : changes[1]
        : changes[0] || changes[1];
    const d = ch.map((x: any, i: number) => {
      const diff = this.getDiff(
        [changes[0] ? changes[0][i] : null, changes[1] ? changes[1][i] : null],
        position,
        parent,
        replaceFields,
        fieldTypes
      );
      return diff ? i + 1 + ': <br/>' + diff : '';
    });
    return d.filter((x: string) => x !== '').join('<hr/>');
  }

  getObjDiff(
    changes: any[],
    position: number,
    parent: string,
    replaceFields: { [key: string]: string },
    fieldTypes: {
      [key: string]:
        | 'string'
        | 'object'
        | 'date'
        | 'dateTime'
        | 'currency'
        | 'objectList'
        | 'objectObject'
        | 'listObject'
        | 'percent';
    }
  ): string {
    return Object.keys(changes[0] || [])
      .concat(Object.keys(changes[1] || []))
      .filter((v, i, a) => a.indexOf(v) === i)
      .map((x, i) => {
        const ch = [
          changes[0] && changes[0][x] ? changes[0][x] : null,
          changes[1] && changes[1][x] ? changes[1][x] : null,
        ];
        return (
          (i > 0 ? ': <br/>' : '') +
          this.getDiff(ch, position, parent, replaceFields, fieldTypes)
        );
      })
      .join('<hr/>');
  }

  static getFieldName(
    field: string,
    replaceFields: { [key: string]: string }
  ): string {
    return (replaceFields[field] || field)
      .split('_')
      .map((x) => x[0].toUpperCase() + x.slice(1))
      .join(' ');
  }
}
