import { autoserialize } from 'cerialize';
import { Pricing } from './pricing';

export class PaymentDetails {
  @autoserialize application_fee: number; //resupply fee
  @autoserialize stripe_fee: number;
  @autoserialize net_amount: number;
  @autoserialize total_amount: number; //final quote including gratuity

  constructor(payload: IPaymentDetails) {
    this.application_fee = payload?.application_fee;
    this.stripe_fee = payload?.stripe_fee;
    this.net_amount = payload?.net_amount;
    this.total_amount = payload?.total_amount;
  }

  public static OnSerialized(
    instance: PaymentDetails,
    json: PaymentDetails
  ): void {
    Object.keys(json).forEach((key) => {
      json[key] =
        json[key] !== null ? Pricing.formatPrice(json[key] * 100) : null;
    });
  }

  public static OnDeserialized(
    instance: PaymentDetails,
    json: PaymentDetails
  ): void {
    Object.keys(instance).forEach((key) => {
      instance[key] =
        instance[key] !== null
          ? Pricing.formatPrice(instance[key] / 100)
          : null;
    });
  }
}

export interface IPaymentDetails {
  application_fee: number;
  stripe_fee: number;
  net_amount: number;
  total_amount: number;
}
