import { Component, Input } from '@angular/core';
import { HistoryChange, HistoryEvent, WindowSize } from '@domains';
import {
  CharityService,
  DonationsService,
  JourneysService,
  LeadsService,
  LocalStorageService,
  MarketsService,
  OrganizationsService,
  PartnerService,
  PhoneConfigurationService,
  ScheduleHistoryService,
  StoresService,
  TrucksService,
  UsersService,
  XmilesService,
} from '@rspl-api';
import { Observable, Subscription, takeUntil } from 'rxjs';
import { Responsive, ResponsiveService } from '../responsive';
import { HistoryConfig } from './history-config';
import { HistoryEntity } from './history-entity';
import { HistoryService } from './history.service';
@Component({
  selector: 'rspl-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent extends Responsive {
  history: Array<HistoryEvent> = new Array<HistoryEvent>();
  item: any;
  smallWindows: WindowSize[] = [WindowSize.XS, WindowSize.SM];
  changes: HistoryChange[] = [];
  listSelector$?: Subscription;
  itemSelector$?: Subscription;

  sort: 'desc' | 'asc';
  sortedChanges: HistoryChange[] = [];

  initialEvent: string;
  initialValue: { [key: string]: string };

  #config!: HistoryConfig;
  @Input() set config(config: HistoryConfig) {
    this.#config = config;
    if (config.itemIds) {
      this.listSelector$?.unsubscribe();
      this.itemSelector$?.unsubscribe();
      this.item = undefined;
      this.listSelector$ = this.service
        ?.history(config.itemIds)
        .pipe(takeUntil(this.destroy$))
        .subscribe((history) => {
          this.history = history.filter(
            (h) => Object.keys(h.changes).length > 0
          );
          this.historyService
            .mapChanges(
              this.history,
              this.config.users,
              this.config.replaceFields,
              this.config.fieldTypes,
              this.config.ignoreFields
            )
            .subscribe((res) => {
              this.changes = res;
              if (this.config.initialEvent) {
                this.initialEvent = this.historyService.getEvent(
                  this.config.initialEvent
                );
                this.initialValue = this.changes
                  .find((h) => h.event === this.initialEvent)
                  .fields.reduce(
                    (obj, item) =>
                      Object.assign(obj, { [item.name]: item.newValue }),
                    {}
                  );
              }
              this.sortChanges();
            });
        });
      if (this.config.shouldGetItem)
        this.itemSelector$ = this.service
          ?.find?.(config.itemIds[0])
          .pipe(takeUntil(this.destroy$))
          .subscribe((item) => {
            this.item = item;
          });
    }
  }

  changeSort() {
    this.sort = this.sort === 'asc' ? 'desc' : 'asc';
    this.localStorage.setItem('RSPL_HISTORY_SORT', this.sort);
    this.sortChanges();
  }

  sortChanges() {
    if (this.sort === 'desc') {
      this.sortedChanges = [...this.changes];
    } else {
      this.sortedChanges = [...this.changes].reverse();
    }
  }

  get config(): HistoryConfig {
    return this.#config;
  }

  get service():
    | {
        find?(id: string): Observable<any>;
        history(ids: string[]): Observable<Array<HistoryEvent>>;
      }
    | undefined {
    switch (this.config.entity) {
      case HistoryEntity.CHARITY:
        return this.charityService;
      case HistoryEntity.DONATION:
        return this.donationsService;
      case HistoryEntity.LEAD:
        return this.leadsService;
      case HistoryEntity.JOURNEY:
        return this.journeyService;
      case HistoryEntity.MARKET:
        return this.marketService;
      case HistoryEntity.PARTNER:
        return this.partnerService;
      case HistoryEntity.SCHEDULE:
        return this.scheduleHistoryService;
      case HistoryEntity.STORE:
        return this.storeService;
      case HistoryEntity.TRUCK:
        return this.truckService;
      case HistoryEntity.USER:
        return this.userService;
      case HistoryEntity.XMILE:
        return this.xmileService;
      case HistoryEntity.ORGANIZATION:
        return this.organizationsService;
      case HistoryEntity.PHONE_CONFIGURATION:
        return this.phoneConfigurationService;
      default:
        return undefined;
    }
  }

  constructor(
    public override responsiveService: ResponsiveService,
    private localStorage: LocalStorageService,
    private historyService: HistoryService,
    private donationsService: DonationsService,
    private leadsService: LeadsService,
    private charityService: CharityService,
    private partnerService: PartnerService,
    private storeService: StoresService,
    private truckService: TrucksService,
    private scheduleHistoryService: ScheduleHistoryService,
    private xmileService: XmilesService,
    private marketService: MarketsService,
    private userService: UsersService,
    private journeyService: JourneysService,
    private organizationsService: OrganizationsService,
    private phoneConfigurationService: PhoneConfigurationService
  ) {
    super(responsiveService);
    this.sort =
      (this.localStorage.getItem('RSPL_HISTORY_SORT') as 'asc' | 'desc') ||
      'asc';
  }
}
