import { autoserialize, autoserializeAs } from 'cerialize';
import { Pricing } from './pricing';

export class DonationDiscount implements IDonationDiscount {
  @autoserialize small: number;
  @autoserializeAs('max_small_specification') maxSmallSpecification: number;
  @autoserialize max: number;
  @autoserialize valid: boolean;

  constructor(payload: IDonationDiscount) {
    this.small = payload?.small;
    this.max = payload?.max;
    this.maxSmallSpecification = payload?.maxSmallSpecification;
    this.valid = payload?.valid;
  }

   public static OnSerialized(instance: DonationDiscount, json: any): void {
      json.small =
        json.small !== null ? Pricing.formatPrice(json.small * 100) : null;
      json.max =
        json.max !== null ? Pricing.formatPrice(json.max * 100) : null;
    }
  
    public static OnDeserialized(instance: DonationDiscount, json: any): void {
      instance.small =
        instance.small !== null
          ? Pricing.formatPrice(instance.small / 100)
          : null;
      instance.max =
        instance.max !== null
          ? Pricing.formatPrice(instance.max / 100)
          : null;
    }
}
export interface IDonationDiscount {
  small: number; //per item discount
  maxSmallSpecification: number; //number of initial small discounted
  max: number; //max discount value
  valid: boolean; //was discount really applied
}
