<table class="static-table full-width {{ app }}" cellspacing="0">
  <thead>
    <tr>
      <th>Items</th>
      <th [ngStyle]="{ 'text-align': design2 ? 'center' : 'right' }">
        Quantity
      </th>
      <th style="text-align: right" *ngIf="!isCharityApp">Price</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Bags/boxes of clothes/housewares</td>
      <td [ngStyle]="{ 'text-align': design2 ? 'center' : 'right' }">
        <span>
          {{ specification.small }}
        </span>
      </td>
      <td *ngIf="!isCharityApp"></td>
    </tr>
    <tr class="total" *ngIf="!isCharityApp" class="primary-row top-border-row">
      <td colspan="2">Total</td>
      <td style="text-align: right">
        {{ quote.total_amount | currency }}
      </td>
    </tr>
  </tbody>
</table>
