import { autoserialize, autoserializeAs } from 'cerialize';
import { Pricing } from './pricing';

export enum SponsorshipState {
  ACTIVE = 'active',
  PAUSED = 'paused',
  DISABLED = 'disabled',
}
export enum SponsorshipAlgorithmVersion {
  STATIC_V1 = 'static_v1',
  EFFICIENT_V1 = 'efficient_v1',
}

export enum SponsorshipStripeStatus {
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
  SUCCESS = 'succeeded',
}

export class Sponsorship implements ISponsorship {
  @autoserializeAs('charity_id') charityId?: string;
  @autoserialize id: string;
  @autoserialize zips?: string[];
  @autoserialize state: SponsorshipState;
  @autoserialize amount?: number;
  @autoserializeAs('max_small') maxSmall?: number;
  @autoserializeAs('stripe_status')
  stripeStatus: SponsorshipStripeStatus | null;
  @autoserializeAs('algorithm_version')
  algorithmVersion?: SponsorshipAlgorithmVersion;
  @autoserializeAs('email_notification') emailNotification?: boolean;

  constructor(payload?: ISponsorship) {
    this.charityId = payload?.charityId;
    this.id = payload?.id;
    this.zips = payload?.zips;
    this.state = payload?.state;
    this.amount = payload?.amount;
    this.maxSmall = payload?.maxSmall;
    this.stripeStatus = payload?.stripeStatus;
    this.algorithmVersion = payload?.algorithmVersion;
    this.emailNotification = payload?.emailNotification;
  }

  public static OnSerialized(instance: Sponsorship, json: any): void {
    json.amount =
      json.amount !== null ? Pricing.formatPrice(json.amount * 100) : null;
    json.max_small =
      json.max_small !== null ? Pricing.formatPrice(json.max_small * 100) : null;
  }

  public static OnDeserialized(instance: Sponsorship, json: any): void {
    instance.amount =
      instance.amount !== null
        ? Pricing.formatPrice(instance.amount / 100)
        : null;
    instance.maxSmall =
      instance.maxSmall !== null
        ? Pricing.formatPrice(instance.maxSmall / 100)
        : null;
  }
}
export interface ISponsorship {
  charityId?: string;
  id?: string;
  zips?: string[];
  state?: SponsorshipState;
  amount?: number;
  maxSmall?: number;
  stripeStatus?: SponsorshipStripeStatus;
  algorithmVersion?: SponsorshipAlgorithmVersion;
  emailNotification?: boolean;
}
