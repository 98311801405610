export enum NotificationTrigger {
  code_lookup = 'code_lookup',
  donation_auth_complete = 'donation_auth_complete',
  donation_charity_assign = 'donation_charity_assign',
  donation_checkout_payment = 'donation_checkout_payment',
  donation_checkout_setup = 'donation_checkout_setup',
  donation_code_payment = 'donation_code_payment',
  donation_code_update = 'donation_code_update',
  donation_create = 'donation_create',
  donation_donor_accept = 'donation_donor_accept',
  donation_donor_cancel = 'donation_donor_cancel',
  donation_donor_confirm = 'donation_donor_confirm',
  donation_donor_reschedule = 'donation_donor_reschedule',
  donation_manual_payment = 'donation_manual_payment',
  donation_offline_payment = 'donation_offline_payment',
  donation_partner_accept = 'donation_partner_accept',
  donation_partner_arrive = 'donation_partner_arrive',
  donation_partner_arrive_at_store = 'donation_partner_arrive_at_store',
  donation_partner_assign = 'donation_partner_assign',
  donation_partner_cancel = 'donation_partner_cancel',
  donation_partner_complete = 'donation_partner_complete',
  donation_partner_decline = 'donation_partner_decline',
  donation_partner_en_route = 'donation_partner_en_route',
  donation_partner_en_route_to_store = 'donation_partner_en_route_to_store',
  donation_partner_send_quote = 'donation_partner_send_quote',
  donation_partner_skip_payment = 'donation_partner_skip_payment',
  donation_payment_complete = 'donation_payment_complete',
  donation_payment_failure = 'donation_payment_failure',
  donation_refund = 'donation_refund',
  donation_review = 'donation_review',
  donation_setup_complete = 'donation_setup_complete',
  donation_submit = 'donation_submit',
  donation_update = 'donation_update',
  donation_timed_assign = 'donation_timed_assign',
  donor_unconfirmed_reminder = 'donor_unconfirmed_reminder',
  partner_assigned_reminder = 'partner_assigned_reminder',
  partner_accepted_reminder = 'partner_accepted_reminder',
  partner_reminder_low = 'partner_reminder_low',
  partner_reminder_moderate = 'partner_reminder_moderate',
  partner_reminder_high = 'partner_reminder_high',
  partner_reminder_urgent = 'partner_reminder_urgent',
  reset_password = 'reset_password',
  lead_manual_submit = 'lead_manual_submit',
  donation_auto_accept = 'donation_auto_accept',
  donation_code_update_request_eta = 'donation_code_update_request_eta',
  donation_update_eta = 'donation_update_eta',
  donation_auto_cancel = 'donation_auto_cancel',
  lead_abandoned = 'lead_abandoned',
  lead_abandoned_reminder_low = 'lead_abandoned_reminder_low',
  lead_abandoned_reminder_high = 'lead_abandoned_reminder_high',
  courier_accept = 'courier_accept',
  courier_en_route = 'courier_en_route',
  courier_arrived = 'courier_arrived',
  courier_en_route_to_store = 'courier_en_route_to_store',
  courier_arrive_at_store = 'courier_arrive_at_store',
  courier_complete = 'courier_complete',
  courier_cancel = 'courier_cancel',
}
