import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AppType,
  Donation,
  ENVIRONMENT,
  Environment,
  Lead,
  Pricing,
  Specification,
} from '@domains';
import { DesignService, Designable } from '../designable';
import { ResponsiveService } from '../responsive';
import { DiscountInfoDialogComponent } from './discount-info-dialog/discount-info-dialog.component';

@Component({
  selector: 'rspl-donation-estimate',
  templateUrl: './donation-estimate.component.html',
  styleUrls: ['./donation-estimate.component.scss'],
})
export class DonationEstimateComponent extends Designable implements OnChanges {
  @Input() pricing!: Pricing;

  initialSpecification!: Specification;
  #specification!: Specification;
  @Input() set specification(specification: Specification) {
    this.#specification = specification;
    this.initialSpecification = new Specification({ ...specification });
  }
  #leadOrDonation!: Lead | Donation;
  @Input() set leadOrDonation(leadOrDonation: Lead | Donation) {
    this.#leadOrDonation = leadOrDonation;
    this.sponsoredAmount =
      Pricing.calculateSponsoredAmount(this.specification, this.leadOrDonation.pricing, this.leadOrDonation.discount, this.leadOrDonation.sponsorship_algorithm, this.leadOrDonation.donatableSmall);
  }

  get leadOrDonation(): Lead | Donation {
    return this.#leadOrDonation;
  }
  get specification(): Specification {
    return this.#specification;
  }

  @Input() showZeros = true;
  @Input() gratuity?: number;
  @Input() bookingFee?: number | null;
  @Input() showPrices = true;
  @Input() showUnitPrices = true;
  @Input() collapsable = false;
  @Input() disabled = false;
  @Input() editable = false;
  @Input() totalLabel = 'Total';
  @Input() bookingLabel = 'Booking Fee';
  @Input() showBookingFee: 'regular' | 'title' | 'none' = 'title';
  @Input() showTodaysCharges = false;
  @Input() showDiscount = false;
  @Input() discountedBy? = '';
  @Input() includeBookingInTotal = false;
  @Input() isCalculating = false;
  @Output() onSpecificationChange = new EventEmitter();
  public donationPrice?: number;
  public fuelFee?: number;
  isCharityApp: boolean;
  isCaptainApp: boolean;
  isDriverApp: boolean;
  isDonorApp: boolean;
  app: AppType;
  sponsoredAmount: number = 0;

  constructor(
    @Inject(ENVIRONMENT) private config: Environment,
    protected dialog: MatDialog,
    override designService: DesignService,
    override responsiveService: ResponsiveService
  ) {
    super(designService, responsiveService);
    this.isCharityApp = this.config.app === AppType.CHARITY;
    this.isDriverApp = this.config.app === AppType.DRIVER;
    this.isDonorApp = this.config.app === AppType.DONOR;
    this.isCaptainApp =
      this.config.app === AppType.CAPTAIN ||
      this.config.app === AppType.ZENDESK;
    this.app = this.config.app;
  }

  ngOnChanges(): void {
    this.updatePrice();
  }

  updatePrice() {
    if (this.showPrices && this.pricing) {
      this.donationPrice = Pricing.getTotalPriceFormatted(
        this.specification,
        this.pricing,
        this.gratuity
      );
      this.fuelFee = this.pricing.fuelFee
        ? (Pricing.getTotalBasePrice(this.specification, this.pricing) *
            this.pricing.fuelFee) /
          100
        : 0;
    }
    this.sponsoredAmount =
      Pricing.calculateSponsoredAmount(this.specification, this.leadOrDonation.pricing, this.leadOrDonation.discount, this.leadOrDonation.sponsorship_algorithm, this.leadOrDonation.donatableSmall);
  }

  onKeyUp() {
    if (this.leadOrDonation.discount?.valid) {
      this.onSpecificationChange.emit();
    } else {
      this.updatePrice();
    }
  }

  checkValue(event: any) {
    const val = Number(event.target.value.replaceAll(/[^\d]/g, ''));
    return isNaN(val) || val === undefined ? 0 : val;
  }

  focusInput(event: any) {
    event.target.select();
  }

  public get isChanged() {
    return !this.initialSpecification.isSameAs(this.specification);
  }

  showDiscountInfoDialog() {
    this.dialog.open(DiscountInfoDialogComponent, {
      panelClass: 'discount-info-dialog',
      data: {
        charityName: this.leadOrDonation.charity?.name
      }
    });
  }
}
