import { autoserialize } from 'cerialize';
import { Pricing } from './pricing';

export class SponsorshipAlgorithm implements ISponsorshipAlgorithm {
  @autoserialize max_discount?: number;
  @autoserialize max_small?: number;
  @autoserialize min_quote?: number;

  constructor(payload: ISponsorshipAlgorithm) {
    this.max_discount = payload?.max_discount;
    this.max_small = payload?.max_small;
    this.min_quote = payload?.min_quote;
  }

  public static OnSerialized(instance: SponsorshipAlgorithm, json: any): void {
    json.max_discount =
      json.max_discount !== null
        ? Pricing.formatPrice(json.max_discount * 100)
        : null;
    json.max_small =
      json.max_small !== null
        ? Pricing.formatPrice(json.max_small * 100)
        : null;
    json.min_quote =
      json.min_quote !== null
        ? Pricing.formatPrice(json.min_quote * 100)
        : null;
  }

  public static OnDeserialized(
    instance: SponsorshipAlgorithm,
    json: any
  ): void {
    instance.max_discount =
      instance.max_discount !== null
        ? Pricing.formatPrice(instance.max_discount / 100)
        : null;
    instance.max_small =
      instance.max_small !== null
        ? Pricing.formatPrice(instance.max_small / 100)
        : null;
    instance.min_quote =
      instance.min_quote !== null
        ? Pricing.formatPrice(instance.min_quote / 100)
        : null;
  }
}

export interface ISponsorshipAlgorithm {
  max_discount?: number;
  max_small?: number;
  min_quote?: number;
}
