import {
  Component,
  Inject,
  Input
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AppType,
  ENVIRONMENT,
  Environment,
  Quote,
  Specification
} from '@domains';
import { DesignService, Designable } from '../designable';
import { ResponsiveService } from '../responsive';

@Component({
  selector: 'rspl-courier-quote',
  templateUrl: './courier-quote.component.html',
  styleUrls: ['./courier-quote.component.scss'],
})
export class CourierQuoteComponent extends Designable {
  @Input() quote!: Quote;

  @Input() specification!: Specification;
  isCharityApp: boolean;
  isCaptainApp: boolean;
  isDonorApp: boolean;
  app: AppType;
  sponsoredAmount: number = 0;

  constructor(
    @Inject(ENVIRONMENT) private config: Environment,
    protected dialog: MatDialog,
    override designService: DesignService,
    override responsiveService: ResponsiveService
  ) {
    super(designService, responsiveService);
    this.isCharityApp = this.config.app === AppType.CHARITY;
    this.isDonorApp = this.config.app === AppType.DONOR;
    this.isCaptainApp =
      this.config.app === AppType.CAPTAIN ||
      this.config.app === AppType.ZENDESK;
    this.app = this.config.app;
  }
}
